.blogMaze{
    width: 300px;
    height: 300px;
    clip-path : polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid black;
}

.blogs-header{
    height: 60vh;
}
.blogs-Heading-text{
    font-size: 144px;
    font-weight: 700;
    line-height: 170px;
    letter-spacing: 0.25em;
    /* color: #F8F8F869; */
    -webkit-text-stroke: #F8F8F869 3px;
    color: transparent;
    background:url(../images/back2.png);
    -webkit-background-clip: text;
    background-position: 0 0;
    animation: back 20s linear infinite;
}
@keyframes back{
    100%{
        background-position: 2000px 0;
    }
}

@media screen and (max-width: 600px){
    .blogMaze{
        width: 160px;
        height: 160px;
        clip-path : polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border: 1px solid black;
    }
    .blogs-header{
        height: 20vh;
    }
    .blogs-Heading-text{
        font-size: 40px;
        line-height: 80px;
        letter-spacing: 0.25em;
        text-align: center;
    }
}