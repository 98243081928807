nav{
    display: none;
    position: fixed!important;
}
.content {
    max-width: 1250px;
    margin: auto;
    padding: 15px 34px;
 }
 .bar {
    position: fixed!important;
    z-index: 1005;
    width: 100%;
    transition: all 0.3s ease;
 }
 .bar .content .gfg-logo {
    width: 40px;
    color: black;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-bottom: 10px;
 }
 .bar .content .make-smaller {
    width: 30px;
    transition: 0.2s ease-in-out;
 }
 .bar .content .menu-list li a {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #343434;
 }
 .sticky {
    position: fixed!important;
    padding: 0px;
    height: 70px;
    transition: 0.2s ease-in-out;
 }
 .sticky > .content li a {
    color: black  !important;
 }
 .sticky > .content {
    padding: 5px 34px;
    transition: 0.2s ease-in-out;
 }
 .bar .content {
    display: flex;
    align-items: center!important;
    justify-content: space-between;

 }
 .bar .logo a {
    font-size: 30px;
    margin-left: 10px;
    text-decoration: none;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
 }
 .bar .menu-list {
    display: inline-flex;
 }
 .menu-list li {
    list-style: none;
    padding-top: 20px;
 }
 .menu-list li a {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-left: 35px;
    text-decoration: none;
    transition: all 0.3s ease;
 }
 .menu-list li a:hover {
    color: #007bff;
 }
 .bar .content .burger-menu {
    width: 50px;
    height: 50px;
    opacity: 1;
    display: flex;
    justify-content: center;
    place-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 5px;
    transition: 0.3s ease-in-out;
    background-color: none;
    display: none;
 }
 
 .bar .content .burger-menu .line-1 {
    width: 24px;
    height: 2px;
    background-color: #fefefe;
    margin: 3px 0;
    transition: 0.3s ease-in-out;
 }
 .bar .content .burger-menu .line-2-container {
    width: 24px;
    height: 2px;
    margin: 3px 0;
 }
 .bar .content .burger-menu .line-2 {
    width: 11px;
    height: 2px;
    background-color: #fefefe;
    position: relative;
    left: 0;
    transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
 }
 .bar .content .burger-menu .line-3 {
    width: 24px;
    height: 2px;
    background-color: #fefefe;
    margin: 3px 0;
    transition: 0.3s ease-in-out;
 }
 
 .bar .content .burger-menu:hover > .line-2-container .line-2 {
    width: 24px;
    transition: 0.3s ease-in-out;
 }
 .bar .content .burger-menu:hover {
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.1);
    transition: 0.3s ease-in-out;
 }
 
 .cross-state-1 {
    margin: 0 !important;
    position: relative;
    top: 2px;
    transform: rotate(45deg);
    transition: 0.3s ease-in-out;
 }
 .cross-state-2 {
    margin: 0 !important;
    width: 0px !important;
    transition: 0.3s ease-in-out;
 }
 .cross-state-3 {
    margin: 0 !important;
    position: relative;
    top: -2px;
    transform: rotate(-45deg);
    transition: 0.3s ease-in-out;
 }
 
 .bar .menu-list li a:hover {
    padding-bottom: 10px;
    border-bottom: 3px solid #333;
 }
 
 @media (max-width: 600px) {
    nav{
        display: block;
        position: fixed!important;
        z-index: 150;
    }
    body.disabledScroll {
       overflow: hidden;
    }
    .subButton {
       display: none;
    }
    .bar .content .gfg-logo {
       float: left;
       margin-bottom: 0px;
    }
    .sticky > .content {
       padding: 15px 34px;
    }
    .bar .content .make-smaller {
    width: 40px;
    }
    .bar .logo a {
       float: left;
    }
    .bar .content .burger-menu {
       display: flex;
    }
    .bar .content .menu-list li a {
       font-family: "Montserrat";
       font-weight: 600;
       color: #fefefe;
       margin-left: 0px;
    }
    .bar .menu-list {
       position: fixed!important;
       top: 0;
       left: 0;
       height: 100vh;
       width: 100%;
       background: #121212;
       background-color: black;
       display: block;
       padding: 40px 0;
       text-align: center;
       clip-path: circle(25px at calc(100% - 60px) calc(0% + 42px));
       transition: all 0.3s ease;
       z-index: -1;
    }
    .bar.sticky .menu-list {
       clip-path: circle(25px at calc(100% - 60px) calc(0% + 40px));
    }
    .bar .menu-list.active {
       clip-path: circle(75%);
    }
    .bar .menu-list li {
       margin-top: 50px;
       padding: 20px 0;
    }
    .bar .menu-list li a {
       font-size: 23px;
       padding: 5px 0;
    }
    .sticky > .content li a {
       color: white  !important;
    }
 }