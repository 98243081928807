.Journey-header{
    width: 100%;
    height: 50vh;
    /* border-bottom: 2px solid gray; */
    /* z-index:99; */
}
.video-background{
    position: absolute;
    width: 100%;
    height: 50vh;
    /* left: 50%; */
    object-fit: cover;
    /* z-index: -1; */
}
.journeyMaze{
    border: 3px solid transparent;
    width: 150px;
    height: 150px;
    clip-path : polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.journey-header-text{
    position: absolute;
    /* width: 875px; */
    height: 88px;
    left: 280px;
    top:110px;
    color:#E1E1E1;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04em;
}

@media screen and (max-width: 600px){
    .Journey-header{
        height: 40vh;
    }
    .journey-header-text{
        position: absolute;
        height: 88px;
        left: 10px;
        top:100px;
        color:#E1E1E1;
        font-weight: bold;
        font-size: 28px;
        line-height: 44px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.04em;
    }
}