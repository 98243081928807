*{
    font-family: 'Montserrat', sans-serif;
}
body{
    background-color: #111318; 
    user-select: none;
}

::-webkit-scrollbar{
    width: 0px;
}
.home-section{
    position: relative;
    /* background-color: #111318; */
    height: 100%;
    left: 78px;
    /* z-index: -3; */
    width: calc(100% - 78px);
    transition: all 0.5s ease;
    overflow-x: hidden;
}
.Landing-header{
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 600px;
}
.typingEffect{
    position: absolute;
    top: 200px;
    color:whitesmoke;
    margin-left: 10%;
    font-size: 60px;
    font-weight: 500;
}

.Landing-Heading{
    font-family: 'Montserrat', sans-serif;
    font-size: 144px;
    font-weight: 700;
    line-height: 176px;
    letter-spacing: 0.3em;
    color: #FFFFFF26;
    height: 200px;
}


/* Journey */
.Timeline{
    width: 2px;
    position: relative;
    margin-left: 24px;
    background-color:#e9ecef;
}

/* Tech & Tools Start  */
.TechAndTools-Heading{
    font-size: 100px;
}
.TechAndTools-SubHeading{
    font-family: 'Montserrat', sans-serif;
    font-size: 76px;
    font-weight: 700;
    line-height: 117px;
    letter-spacing: 0.1em;
    text-align: center;
    height: 117px;
    color: #35363B;
    -webkit-box-reflect: below -55px linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.4));
}
.maze{
    width: 100px;
    height: 100px;
    clip-path : polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    /* clip-path:  polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%); */
    background-color: #fff;
    /* background-image: url(../icons/javaScript.png); */
    background-position: center;
    background-size: 70px;
    background-repeat: no-repeat;
    border: 1px solid black;
}
/* Tech & Tools End  */

/* Resume Button On hero Section CSS CODE Start */
.button {
    position: relative;
    padding: 1em 1.5em;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    font-size: 18px;
    margin: 1em 0.8em;
}
.button.type1 {
    color: #f1b331;
}
.button.type1.type1::after, .button.type1.type1::before {
    content: "";
    display: block;
    position: absolute;
    width: 20%;
    height: 20%;
    border: 2px solid;
    transition: all 0.6s ease;
    border-radius: 2px;
}
.button.type1.type1::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #f1b331;
    border-right-color: #f1b331;
}
.button.type1.type1::before {
    top: 0;
    left: 0;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: 
    #df6b41;
    border-left-color: #df6b41;
}
.button.type1.type1:hover:after, .button.type1.type1:hover:before {
    width: 100%;
    height: 100%;
}
/* Resume Button On hero Section CSS CODE End */


@media screen and (max-width: 600px){
    .home-section{
        left: 0px;
        width: calc(100% - 0px);
    }
    /* Landing Hero Section Css Start */
    .typingEffect{
        top: 250px;
        font-size: 22px;
    }
    .typingEffect p:nth-child(1){
        margin: 10px 0px;
    }
    /* Landing Hero Section Css End */

    .Landing-Heading{
        font-size: 50px;
        height: 160px;
    }
    .profileImage{
        display: flex;
        justify-content: space-around;
    }
    .profileImage img{
        width: 200px;
        margin-bottom: 50px;
    }

    /* Tech & tools part */
    .TechAndTools-Heading{
        font-size: 50px;
        line-height: 56px;
        height: 100px;
        text-align: center;
    }
    .TechAndTools-SubHeading{
        font-size: 40px;
        line-height: 50px;
        height: 80px;
    }
    .maze{
        width: 80px;
        height: 80px;
        clip-path : polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
        /* clip-path:  polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%); */
        background-color: #fff;
        /* background-image: url(../icons/javaScript.png); */
        background-position: center;
        background-size: 50px;
        background-repeat: no-repeat;
        border: 1px solid black;
    }
    .button {
        padding: 0.5em 1.0em;
        font-size: 12px;
        margin: 1em 0.8em;
    }
}